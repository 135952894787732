import 'bootstrap/dist/css/bootstrap.css'

import React from 'react';
import {
    Row, Col,
} from 'reactstrap';

import '../icofont.min.css';

// Web theme color root css
import '../components/colors.css';

import '../Terms.css';
import Tdsheader from "../components/Tdsheader";

import '../App.css';
import '../responsive.css';
import Tdscontact from "../components/Tdscontact";
import Tdsfooter from "../components/Tdsfooter";
import {Container} from "reactstrap";

export default function Agreements() {
    const downLoad = (name) => {
        if(typeof window !== "undefined") {
            const {downloadFile} = require("../businessLogic/Firebase");
            downloadFile("articles/" + name).then((link) => {
                const element = document.createElement('a');
                element.setAttribute('href', link);
                element.download = link.split('/').pop()
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            })
        }
    }

    return (
        <div className={"App"}>
            {/* Navbar  */}
            <Tdsheader active={"articles"} isHome={false} />

            <div className={"section"} id={"articles"}>
                <h1 className={"heading mt-5 mb-1 ml-3 ml-sm-5"}>Articles</h1>
                <br/><br/>

                <Container>
                    <Row>
                        <Col>
                            <h4>A thematic look at the application of hydraulics in mechanical equipment</h4>
                            <h6>Introduction</h6>
                            <p>
                                All of these tools either facilitate the operation of mechanical affairs or have generally replaced the use of direct force by the human body that we have seen most of them in the form of Hydraulic pumps,
                                Hydraulic Boosters and Jacks. On the other hand, the mechanism of some is eliminating movement shocks or simple tolerance of vibrations or instantaneous shocks which on the one hand,
                                they improve the safety and health coefficient of the machines, and on the other hand, they regulate the performance or increase the quality of objects' movement or moving parts of the machines,
                                which from this type of hydraulic devices, various types of suspensions or shock absorbers can be referred to.
                                Hydraulic jacks generally increase the scale of the force exerted that the primary force can be generated by human hands or feet or electrical hydraulic pumps and are therefore a reliable tool for moving heavy objects.
                                The source of this ability is the pumping of oil and the pressure density in the drift of hydraulic oils to the jacks.
                                The ratio of the cross-section of the pistons of each jack and the amount of pressure power created in the pumping of the oil determines the power of the jack according to tonnage.
                                This capability results in the ability to apply displacement power at very high levels, including tens or hundreds of tons,
                                which can be assigned to one or a set of jacks depending on the defined application.
                            </p>
                            <br/>
                            <a href={"#"} onClick={() => {downLoad("Article-01.pdf")}}>Download full article</a>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Contact Us Section Start  */}
            <div className="section contactus grapping" id="contact">
                <Tdscontact />
            </div>

            {/* Footer  Section Start */}
            <div className="">
                <Tdsfooter/>
            </div>
        </div>
    );
}